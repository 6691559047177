<template>
  <div class="conteudo-interno">
    <div class="nova-comunicacao-container">
      <h1>Processamento Ranking</h1>
      <div class="container-btns" v-if="perfil != 5 && perfil != null">
        <button class="btn btn-laranja" @click="adicionarRanking">Processar Ranking</button>
      </div>
      <form @submit.prevent class="formulario-container">
        <div class="d-md-flex">
          <div class="formulario-item">
            <label class="formulario-item-label">Regional</label>
            <multiselect v-model="form.tipoRanking" label="descricao" track-by="descricao" :options="tipoRankings"
              :searchable="false" :multiple="false" placeholder="" select-label="Selecionar" deselect-label="Remover"
              selected-label="Selecionado" />
          </div>

          <div class="formulario-item">
            <label class="formulario-item-label">CPF/CNPJ</label>
            <input type="text" v-model="form.cpf" v-mask="verificaCpfCnpj()" placeholder="CPF/CNPJ" />
          </div>

          <div class="formulario-item">
            <label class="formulario-item-label">Mês</label>
            <multiselect v-model="form.mes" label="nome" track-by="id" :options="opcoesMeses" :searchable="false"
              :multiple="false" placeholder="" select-label="Selecionar" deselect-label="Remover"
              selected-label="Selecionado" />
          </div>
          <div class="formulario-item">
            <label class="formulario-item-label">Ano</label>
            <input type="text" v-model="form.ano" placeholder="Ano" />
          </div>
        </div>

        <div class="container-btns">
          <button class="btn btn-cancelar" @click="limparFiltro">
            Limpar
          </button>
          <button class="btn btn-laranja" @click="listar(1, true)">
            Filtrar
          </button>
        </div>
      </form>

      <transition name="fade-left" mode="out-in">
        <div v-if="semDados" class="tabela-sem-dados" key="Vazio">
          <p>Sem dados</p>
        </div>
      </transition>

      <div v-if="rankings.length > 0" class="tabela-informacoes" key="Lista">
        <div class="paginacao">
          <button :disabled="paginaBusca == 1" @click="pagAnterior">Anterior</button>
          <p>{{ paginaBusca }}</p>
          <button :disabled="ultimoItem" @click="proxPagina">Próxima</button>
        </div>

        <div class="tabela-linha tabela-linha-titulo">
          <div class="tabela-linha-nome">
            <p>DATA</p>
          </div>
          <div class="tabela-linha-nome">
            <p>CPF/CNPJ</p>
          </div>
          <div class="tabela-linha-nome">
            <p>TIPO RANKING</p>
          </div>
          <div class="tabela-linha-nome">
            <p>POSIÇÃO REGIONAL</p>
          </div>
          <div class="tabela-linha-nome">
            <p>POSIÇÃO NACIONAL</p>
          </div>
          <!-- <div class="tabela-linha-nome"><p>PERCENTUAL</p></div> -->
          <div class="tabela-linha-nome">
            <p>MÊS VIGENTE</p>
          </div>
          <div class="tabela-linha-nome">
            <p>ANO VIGENTE</p>
          </div>
          <!-- <div class="tabela-linha-btns"></div> -->
        </div>
        <div v-for="(ranking, index) in rankings" :key="index" class="tabela-linha tabela-linha-informacoes">
          <div class="tabela-linha-nome">
            <p>{{ formatarData(ranking.dataCadastro) }}</p>
          </div>
          <div class="tabela-linha-nome">
            <p v-if="ranking.cpf.length === 14">{{ ranking.cpf | formataCNPJ }}</p>
            <p v-else>{{ ranking.cpf | formataCPF }}</p>
          </div>

          <div class="tabela-linha-nome">
            <p>{{ ranking.tipoRanking }}</p>
          </div>
          <div class="tabela-linha-nome">
            <p>{{ ranking.posicaoRegional }}°</p>
          </div>
          <div class="tabela-linha-nome">
            <p>{{ ranking.posicaoNacional }}°</p>
          </div>
          <!-- <div class="tabela-linha-nome">
            <p>{{ ranking.percentual }}%</p>
          </div> -->
          <div class="tabela-linha-nome">
            <p>{{ ranking.mesVigente }}</p>
          </div>
          <div class="tabela-linha-nome">
            <p>{{ ranking.anoVigente }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listarRankings } from "@/services/apuracao-ranking";
import { listarRegioes } from "@/services/autorizadas";
import { opcoesMeses } from '@/utils/json';
import { masks } from "@/assets/js";
import Multiselect from "vue-multiselect";
import moment from "moment";

export default {
  name: "RankingListar",
  components: {
    Multiselect,
  },
  data() {
    return {
      opcoesMeses: opcoesMeses.mes,
      semDados: true,
      paginaBusca: 1,
      cpf: "",
      rankings: [],
      tipoRankings: [],
      form: {
        tipoRanking: "",
        mes: "",
        cpf: "",
        ano: "",
      },
      perfil: null
    };
  },

  filters: {
    formataCPF: masks.formataCPF,
    formataCNPJ: masks.formataCNPJ
  },

  created() {
    this.listar(1, false);
    this.listarObterRanking();
    this.perfil = localStorage.getItem('perfil');
  },

  methods: {
    verificaCpfCnpj() {
      if (this.form.cpf.length <= 14) {
        return '###.###.###-##'
      } else if (this.form.cpf.length >= 14) {
        return '##.###.###/####-##'
      }
    },
    listarObterRanking() {
      listarRegioes().then(resp => {
        this.tipoRankings = resp.data
      })
    },
    adicionarRanking() {
      this.$router.push("/ranking/adicionar");
    },

    formatarData(data) {
      return moment(data).format("DD/MM/YYYY");
    },


    listar(pag, tipoFiltro) {
      let data = {};
      if (tipoFiltro) {
        data = {
          nomeUsuario: "",
          cpf: this.form.cpf.replaceAll(".", "").replace("-", "").replace("/", ""),
          mesVigente: this.form.mes && this.form.mes.id ? this.form.mes.id : 0,
          anoVigente: this.form.ano ? Number(this.form.ano) : 0,
          idTipo: this.form.tipoRanking && this.form.tipoRanking.id ? this.form.tipoRanking.id : 0,
          idNome: 0,
          pagina: pag,
          qtdPorPagina: 15,
        }
      } else {
        data = {
          nomeUsuario: "",
          cpf: "",
          mesVigente: 0,
          anoVigente: 0,
          idTipo: 0,
          idNome: 0,
          pagina: pag,
          qtdPorPagina: 15,
        }
      }
      listarRankings(data).then(resp => {
        if (resp.data.lista.length == 0) {
          this.rankings = [];
          if (pag && pag > 1) {
            this.ultimoItem = true;
          } else {
            this.semDados = true;
          }
        } else {
          resp.data.lista.length < 15 ? (this.ultimoItem = true) : (this.ultimoItem = false);
          if (pag) {
            this.paginaBusca = pag;
          }
          this.semDados = false;
          this.rankings = resp.data.lista;
        }
      })
    },
    limparFiltro() {
      this.form = {
        mes: "",
        tipoRanking: {},
        cpf: "",
        ano: "",
      }
      this.rankings = [];

      this.listar(1, false)
    },
    pagAnterior() {
      const pag = --this.paginaBusca;
      let temFiltro = false;
      if (this.form.tipoRanking || this.form.cpf || this.form.mes || this.form.ano) temFiltro = true;
      this.listar(pag, temFiltro);
    },
    proxPagina() {
      const pag = ++this.paginaBusca;
      let temFiltro = false;
      if (this.form.tipoRanking || this.form.cpf || this.form.mes || this.form.ano) temFiltro = true;
      this.listar(pag, temFiltro);
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css" />